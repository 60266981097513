import React from 'react';
import Home from './views/home';

const App = function () {
  return (
    <div className="app">
      <Home />
    </div>
  );
};


export default App;
